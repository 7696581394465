import React, { useState } from "react"
import { Link } from "gatsby"
import Slider from "react-slick-slider"
import SliderNextArrow from "../../components/template-partials/slider-components/slider-next-arrow"
import SliderPrevArrow from "../../components/template-partials/slider-components/slider-prev-arrow"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import FancyImage from "../../components/template-partials/global-components/fancy-image"
import InlineInfocard from "../../components/template-partials/global-components/inline-infocard"
import QuestionInfocard from "../../components/template-partials/global-components/question-infocard"
import AfterTreatmentSubmenu from "../../components/template-partials/after-treatment/after-treatment-submenu"

import { ExploreCard } from "../../components/template-partials/global-components/footer-cards"

import headingBg from "../../images/6.0-headerbg.jpg"
import headingRound from "../../images/after-treatment-nav-image.jpg"


import {
  faExternalLinkAlt,
  faAngleRight, faLink,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"



import hearing from "../../images/6.1.3_hearingaidAsset7.png"
import thyroid from "../../images/6.1.5_thyroid.png"
import reproductive from "../../images/6.1.5_reproductiveorgan.png"
import pancreas from "../../images/6.1.5_diabetes_pancreas.png"
import bones from "../../images/6.1.5_bone.png"
import growthHormones from "../../images/6.1.5_growthhormone.png"
import kidney from "../../images/6.1.5_kidney.png"
import heart from "../../images/6.1.5_heart.png"
import lungs from "../../images/6.1.5_v2lungs.png"


import emotionalLate from "../../images/6.1-emotional-late.jpg"


import zaraTakesOff from "../../images/Zara-takes-off.png"

//icon
//import linkIcon from ../../images/icon-link.svg
//import Accordion from "../../components/template-partials/global-components/accordion/accordion"

//Cool! Export your sass Vars from a top line and use them in JS!
//import * as SassVars from "../../components/variables.module.scss"
import InlineInfocardIcon from "../../components/template-partials/global-components/inline-infocardicon"

const AfterTreatmentSideEffects = () => {
  const [active, setActive] = useState(1)
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: SliderNextArrow,
    prevArrow: SliderPrevArrow,
    responsive: [
      { breakpoint: 1000000, settings: "unslick" },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Layout>
      <SEO
        title="Neuroblastoma Treatment Late and Long-Term Side Effects"
        description="Learn the potential late and long-term side effects of neuroblastoma treatment and the importance of children becoming their own advocate."
      />

      <InteriorPageHeader
        backgroundImage={headingBg}
        roundImage={headingRound}
        alt="Late and Long-Term Side Effects"
      >
        <h1 className="section__heading section__heading--green h1">
         Late and Long-Term Side Effects
        </h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`After Treatment`}>
              <AfterTreatmentSubmenu />
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons />
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <p>
                  A{" "}
                  <Link
                    rel="glossary"
                    to="/support-and-community/glossary/#long-term-side-effect"
                  >
                    long-term side effect
                  </Link>{" "}
                  is a condition that may have occurred as a result of cancer
                  treatment and that can persist for months or years after the
                  end of treatment. A{" "}
                  <Link
                    rel="glossary"
                    to="/support-and-community/glossary/#late-effect"
                  >
                    late effect
                  </Link>{" "}
                  is a side effect that may appear years after the completion of
                  therapy. Both range from mild to severe and are managed by the{" "}
                  <Link
                    rel="glossary"
                    to="/support-and-community/glossary/#long-term-follow-up-care"
                  >
                    long-term follow-up care
                  </Link>{" "}
                  team.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner lg_mt-3 mt-3">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              Potential late effects of neuroblastoma treatment
            </h3>
            <p>
              <Link
                rel="glossary"
                to="/support-and-community/glossary/#long-term-follow-up-care"
              >
                Long-term follow-up care
              </Link>{" "}
              providers monitor for and assist in managing side effects that
              may not occur until years after treatment has ended.{" "}
              <Link
                rel="glossary"
                to="/support-and-community/glossary/#late-effect"
              >
                Late effects
              </Link>{" "}
              of treatment will vary based on where your child’s
              neuroblastoma was located, the type and dose of treatment they
              received, and their age at time of treatment.
            </p>
            <p>
              <strong>
                Late effects of treatment may occur in some children and not
                others.
              </strong>{" "}
              These late effects can be physical, emotional, or cognitive.
            </p>
            <h4 className="h4 tc-viridian jost-med lg_mt-3 mt-3">
              Physical late effects
            </h4>
            <p>
              Your child may experience changes to organs, tissues, and body
              functions. Your child’s growth and development may be affected
              by these changes. Potential effects may include impairment or
              dysfunction to the following:
            </p>
          </div>
        </div>
        <div className="row row--inner mt-2 mb-2">
          <div className="columns">
              <Slider
                className="grid-slider grid-slider--large-3"
                {...settings}
              >
                <div className="text-center">
                  <img
                    src={thyroid}
                    alt="Neuroblastoma treatment effects on thyroid"
                    style={{ maxWidth: `100px` }}
                  />
                  <p className="mt-2">thyroid</p>
                </div>
                <div className="text-center">
                  <img
                    src={hearing}
                    alt="Neuroblastoma treatment effects on hearing"
                    style={{ maxWidth: `45px` }}
                  />
                  <p className="mt-2">hearing</p>
                </div>
                <div className="text-center">
                  <img
                    src={lungs}
                    alt="Neuroblastoma treatment effects on lungs"
                    style={{ maxWidth: `80px` }}
                  />
                  <p className="mt-2">lungs</p>
                </div>
                <div className="text-center">
                  <img
                    src={heart}
                    alt="Neuroblastoma treatment effects on the heart"
                    style={{ maxWidth: `100px` }}
                  />
                  <p className="mt-2">heart</p>
                </div>
                <div className="text-center">
                  <img
                    src={kidney}
                    alt="Neuroblastoma treatment effects on the kidneys"
                    style={{ maxWidth: `120px` }}
                  />
                  <p className="mt-2">kidneys</p>
                </div>
                <div className="text-center">
                  <img
                    src={growthHormones}
                    alt="Neuroblastoma treatment effects on growth hormones"
                    style={{ maxWidth: `100px` }}
                  />
                  <p className="mt-2">growth hormones</p>
                </div>
                <div className="text-center">
                  <img
                    src={bones}
                    alt="Neuroblastoma treatment effects on bone growth"
                    style={{ maxWidth: `45px` }}
                  />
                  <p className="mt-2">bone growth</p>
                </div>
                <div className="text-center">
                  <img
                    src={pancreas}
                    alt="Neuroblastoma treatment effects on metabolic processes"
                    style={{ maxWidth: `100px` }}
                  />
                  <p className="mt-2">metabolic processes (diabetes)</p>
                </div>
                <div className="text-center">
                  <img
                    src={reproductive}
                    alt="Neuroblastoma treatment effects on reproductive organs"
                    style={{ maxWidth: `100px` }}
                  />
                  <p className="mt-2">reproductive organs</p>
                </div>
              </Slider>
          </div>
        </div>
        <div className="row row--inner lg_mt-2 mt-2">
          <div className="columns">
            <p>
              Treatment may also sometimes cause another (secondary) cancer.
            </p>
          </div>
        </div>
        <div className="row row--inner lg_mt-3 mt-3">
          <div className="columns">
            <h4 className="h4 tc-viridian jost-med">
              Cognitive late effects
            </h4>
            <p>
              Your child may experience changes in their ability to learn
              (ie, memory and critical thinking skills) which may impact how
              they perform in school. These effects are more common in
              children who had radiation therapy to their spinal cord,
              brain, and/or head and neck.
            </p>
          </div>
        </div>
        <div className="row row--inner align-middle lg_mt-3 mt-3">
          <div className="columns">
            <h4 className="h4 tc-viridian jost-med">
              Emotional late effects
            </h4>
            <p>
              Your child may experience changes in their mood, feelings, and
              behavior, which may impact their social well-being. In some
              cases, children may suffer from depression or post-traumatic
              stress disorder. If you notice changes in your child’s moods
              and behaviors, speak with your healthcare team or a mental
              health specialist.
            </p>
          </div>
          <div className="columns small-12 large-4 large-text-right text-center mt-3">
            <FancyImage alt="emotional late effects" url={emotionalLate} />
          </div>
        </div>
        <InlineInfocard
          className="lg_mt-4 mt-3"
          text="Follow-up care with specialists should be a part of your child’s plan to help address any late effects."
        />
      </Section>

      <Section>
        <div className="row row--inner">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              Becoming their own advocate
            </h3>
            <p>
              As the years go by and your child grows older, it will be
              important for them to be aware of their unique medical needs.
              Teaching them over time about neuroblastoma and their specific
              journey will increase their awareness and readiness to take
              ownership of their health. Having an updated{" "}
              <Link
                rel="glossary"
                to="/support-and-community/glossary/#treatment-summary"
              >
                treatment summary
              </Link>{" "}
              and care plan that they can share is key in helping them make sure
              they get the care they need.{" "}
            </p>
            <p>
              <a
                data-gtm-event-category="External Link"
                data-gtm-event-action="Click"
                data-gtm-event-label="Visit the Children’s Oncology Group"
                href="https://childrensoncologygroup.org/index.php/the-first-month-off-treatment"
                target="_blank"
                rel="noreferrer"
              >
                If you found these answers helpful, visit the Children’s
                Oncology Group for more information about what happens after
                treatment.{" "}
                <small>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </small>
              </a>
            </p>
          </div>
        </div>
        <QuestionInfocard
          link="/diagnosis/doctor-discussion-guide/"
          className="lg_mt-3 lg_mb-3"
          heading="What late effects of treatment are possible?"
          text="Find questions to ask your child’s doctor about care after treatment ends."
        />
        <InlineInfocardIcon
          className="lg_mt-2 mt-2"
          gtmCat="Internal Link"
          gtmAction="Click"
          gtmLabel="ZARA takes OFF"
          target="_self"
          link={`/support-and-community/downloadable-resources/`}
          text="Use this informative family resource to help guide you and your child through the after-treatment phase of their recovery."
          img={zaraTakesOff}
        />
      </Section>

      <Section>
        <div className="row row--inner">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              COG Survivorship Guidelines
            </h3>
            <p>
              For more information on follow-up recommend based off of your child's treatment course, visit the  {" "}
              <Link
                rel=""
                to="https://childrensoncologygroup.org/survivorshipguidelines"
              >
                Children's Oncology Group Survivorship Page.
              </Link>{" "}
            </p>
          </div>
        </div>
      </Section>

      <InteriorPageFooter>
        <ExploreCard
          single="true"
          title="Is your child planning on attending college?"
          lead="Learn about scholarship opportunities for neuroblastoma survivors."
          url="/support-and-community/support-groups/#college"
        />
      </InteriorPageFooter>
    </Layout>
  )
}

export default AfterTreatmentSideEffects
