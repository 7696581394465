import React from "react"
import * as SassVars from '../../../components/variables.module.scss';
import PropTypes from "prop-types"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InlineInfocardIcon = (props) => {
  return (
    <div className={`row row--inner ` + props.className}>
      <div className="columns">
        <div className="card" style={{backgroundColor: SassVars.viridian, borderColor: SassVars.turquoise }}>
          <div className="row align-middle">
            <div className="columns small-8 large-10 text-left">
              <a data-gtm-event-category={props.gtmCat} data-gtm-event-action={props.gtmAction} data-gtm-event-label={props.gtmLabel} href={props.link} target={props.target ? props.target : "_blank" } rel="noreferrer" className="h5 jost-med tc-white underline">{props.text}</a>
            </div>
            <div className="columns small-4 large-2 text-center">
              <img src={props.img} alt={props.text}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

InlineInfocardIcon.defaultProps = {
  text: 'YOU FORGOT TO ADD A TEXT PROP',
};

InlineInfocardIcon.propTypes = {
  text: PropTypes.node.isRequired
}

export default InlineInfocardIcon
