import React from "react"
import { Link } from "gatsby"

const AfterTreatmentSubmenu = () => {
  return (
    <>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="What Happens Next and When" activeClassName="isActive"  to="/after-treatment/whats-next/" partiallyActive={true}>What Happens Next and When <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Late and Long-Term Side Effects" activeClassName="isActive" partiallyActive={true} to="/after-treatment/late-and-long-term-side-effects/">Late and Long-Term Side Effects <span className="np-arrow">&rsaquo;</span></Link></li>
    </>
  )
}

export default AfterTreatmentSubmenu
